.landing-page-sec{
    /* background: linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84));
    background: -webkit-linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84)); */
    /* background-color: rgb(7 16 45); */
    min-height: 100vh;
    position: relative;
}

.landing-page-sec::before{
    position: absolute;
    content: "";
    background: url(https://cityplots.in/assets/images/about/hero-1.png);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaley(-1);
}
.landing-page-sec .card {
    /* max-width: 350px; */
    /* background-color: rgb(7 16 45); */
    /* background: linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84));
    background: -webkit-linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84)); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    border-radius: 20px;
    border: 0;
    background-color: transparent!important;
  }
  
  .landing-page-sec .small-text, .title,.desc {
    font-weight: 600;
  }
  
  .landing-page-sec .title,.desc {
    margin: 8px 0;
  }
  
  .landing-page-sec .desc {
    color: #fff;
    font-size: 1.6em;
    padding: 1em 0;
  }
  
  .landing-page-sec .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
    margin-top: 10px;
  }
  
  .landing-page-sec .custom-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px 30px;
    text-decoration: none;
  }
  
  .landing-page-sec .custom-links:first-child {
    box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
    background-color: #488aec;
  }
  
  .landing-page-sec .custom-links:last-child {
    box-shadow: 0 4px 6px -1px #0b162531, 0 2px 4px -1px #0e192b17;
    background-color: #fff;
  }
  
  .landing-page-sec .app-icons {
    height: 25px;
    width: 25px;
  }
  
  .landing-page-sec .app-icons svg {
    width: 100%;
    height: 100%;
  }
  
  .landing-page-sec .app-icons svg:last-child {
    fill: #000
  }
  
  .landing-page-sec .button-text {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
  
  .landing-page-sec .button-text span:first-child {
    font-size: 12px;
    font-weight: 600;
  }
  
  .landing-page-sec .google span:first-child {
    color: rgb(219 206 253);
  }
  
  .landing-page-sec .apple span:first-child {
    color: rgb(81 87 108);
  }
  
  .landing-page-sec .google span:last-child {
    color: #fff;
    font-weight: 800;
  }
  
  .landing-page-sec .apple span:last-child {
    color: #000;
    font-weight: 800;
  }

  .landing-page-sec .landing-logo{
    max-width: 20em;
  }