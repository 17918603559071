@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.agent-meeting-room-sec{
    display: flex;
    min-height: 100vh;
    font-family: 'Inter', sans-serif!important;
}

.agent-meeting-room-left-sec{
    background-color: #E6E8ED;
    padding: 2em;
    width: 75%;
}

.agent-meeting-room-right-sec{
    width: 25%;
    background-color: #FAFAFB;
    height: auto;
}

.agent-meeting-info-item{
    display: flex;
    gap: 2em;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.agent-meeting-info-item h4 {
    color: #343434;
    font-size: 2em;
    font-weight: 600;
}

.agent-meeting-info-item p {
    font-size: 1.2em;
    color: #55495F;
    margin-bottom: 0.5em;
    max-width: 600px;
}

.agent-agenda-btn{
    background-color: #1F2730!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color: #1F2730!important;
    padding: 0.8em 2em!important;
    font-size: 1.4em!important;
    font-weight: 500!important;
    color: var(--secondary-color)!important;
}

.agent-agenda-btn:hover,
.agent-agenda-btn:focus,
.agent-agenda-btn:active{
    background-color: #1F2730!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color: #1F2730!important;
    color: var(--secondary-color)!important;
}