@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-section {
  background: #f6f7f8;
  position: relative;
}

.latest-footer-box {
  padding: 0;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: var(--primary-color);
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #212529;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 10px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
  max-width: 400px;
}
.footer-social-icon span {
  color: var(--senary-color);
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  /* color: #fff; */
  font-size: 16px;
  /* margin-right: 15px; */
}

.footer-social-icon li a {
  color: var(--secondary-color) !important;
}

.footer-social-icon li {
  margin-right: 1em;
  width: 2em;
  height: 2em;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-icon li:last-child {
  margin-right: 0;
}

/* .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
  } */

.footer-widget-heading h3 {
  color: #212529;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(--primary-color);
}
.footer-widget ul li {
  /* display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
    margin-top: 0; */
}
.footer-widget ul li a:hover {
  color: var(--primary-color);
}
.footer-widget ul li a {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: var(--quaternary-color);
}
.copyright-area {
  background: #202020;
  padding: 20px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary-color);
  letter-spacing: 0.8px;
}
.copyright-text p a {
  color: var(--primary-color);
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: var(--primary-color);
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.footer-widget ul li {
  margin-top: 0;
  margin-bottom: 1em;
}

.footer-aligin-last {
  display: flex;
  justify-content: flex-end;
}

.footer-aligin-center {
  display: flex;
  justify-content: center;
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.cls-10,
.cls-6 {
  opacity: 0.6;
}

.cls-7 {
  opacity: 0.4;
}

.cls-9 {
  fill: #f4b73b;
}

.cls-11 {
  fill: #f9c358;
}

.cls-12 {
  fill: #9b462c;
}

.cls-13 {
  fill: #aa512e;
}

.cls-14 {
  fill: #7d6aa5;
}

/* animations */

.wheel {
  -webkit-animation: wheel-rotate 6s ease infinite;
          animation: wheel-rotate 6s ease infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes wheel-rotate {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
            animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  100% {
    -webkit-transform: rotate(960deg);
            transform: rotate(960deg);
  }
}

@keyframes wheel-rotate {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
            animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  100% {
    -webkit-transform: rotate(960deg);
            transform: rotate(960deg);
  }
}

.clock-hand-1 {
  -webkit-animation: clock-rotate 3s linear infinite;
          animation: clock-rotate 3s linear infinite;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transform-box: fill-box;
}

.clock-hand-2 {
  -webkit-animation: clock-rotate 6s linear infinite;
          animation: clock-rotate 6s linear infinite;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transform-box: fill-box;
}

@-webkit-keyframes clock-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes clock-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#box-top {
  -webkit-animation: box-top-anim 2s linear infinite;
          animation: box-top-anim 2s linear infinite;
  -webkit-transform-origin: right top;
          transform-origin: right top;
  transform-box: fill-box;
}

@-webkit-keyframes box-top-anim {
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

@keyframes box-top-anim {
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

#umbrella {
  -webkit-animation: umbrella-anim 6s linear infinite;
          animation: umbrella-anim 6s linear infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes umbrella-anim {
  25% {
    -webkit-transform: translateY(10px) rotate(5deg);
            transform: translateY(10px) rotate(5deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

@keyframes umbrella-anim {
  25% {
    -webkit-transform: translateY(10px) rotate(5deg);
            transform: translateY(10px) rotate(5deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

#cup {
  -webkit-animation: cup-rotate 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
          animation: cup-rotate 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transform-box: fill-box;
}

@-webkit-keyframes cup-rotate {
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

@keyframes cup-rotate {
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

#pillow {
  -webkit-animation: pillow-anim 3s linear infinite;
          animation: pillow-anim 3s linear infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes pillow-anim {
  25% {
    -webkit-transform: rotate(10deg) translateY(5px);
            transform: rotate(10deg) translateY(5px);
  }
  75% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

@keyframes pillow-anim {
  25% {
    -webkit-transform: rotate(10deg) translateY(5px);
            transform: rotate(10deg) translateY(5px);
  }
  75% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

#stripe {
  -webkit-animation: stripe-anim 3s linear infinite;
          animation: stripe-anim 3s linear infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes stripe-anim {
  25% {
    -webkit-transform: translate(10px, 0) rotate(-10deg);
            transform: translate(10px, 0) rotate(-10deg);
  }
  75% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}

@keyframes stripe-anim {
  25% {
    -webkit-transform: translate(10px, 0) rotate(-10deg);
            transform: translate(10px, 0) rotate(-10deg);
  }
  75% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}

#bike {
  -webkit-animation: bike-anim 6s ease infinite;
          animation: bike-anim 6s ease infinite;
}

@-webkit-keyframes bike-anim {
  0% {
    -webkit-transform: translateX(-1300px);
            transform: translateX(-1300px);
  }
  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
            animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    -webkit-transform: translateX(1300px);
            transform: translateX(1300px);
  }
}

@keyframes bike-anim {
  0% {
    -webkit-transform: translateX(-1300px);
            transform: translateX(-1300px);
  }
  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
            animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    -webkit-transform: translateX(1300px);
            transform: translateX(1300px);
  }
}

#rucksack {
  -webkit-animation: ruck-anim 3s linear infinite;
          animation: ruck-anim 3s linear infinite;
  -webkit-transform-origin: top;
          transform-origin: top;
  transform-box: fill-box;
}

@-webkit-keyframes ruck-anim {
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
}

@keyframes ruck-anim {
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
}

.circle {
  -webkit-animation: circle-anim ease infinite;
          animation: circle-anim ease infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
  -webkit-perspective: 0px;
          perspective: 0px;
}

.circle.c1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.circle.c2 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}

.circle.c3 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.circle.c4 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.circle.c5 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.circle.c6 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}

@-webkit-keyframes circle-anim {
  50% {
    -webkit-transform: scale(0.2) rotateX(360deg) rotateY(360deg);
            transform: scale(0.2) rotateX(360deg) rotateY(360deg);
  }
}

@keyframes circle-anim {
  50% {
    -webkit-transform: scale(0.2) rotateX(360deg) rotateY(360deg);
            transform: scale(0.2) rotateX(360deg) rotateY(360deg);
  }
}

.four,
#ou {
  -webkit-animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
          animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}

.four.a {
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  transform-box: fill-box;
}

.four.b {
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  transform-box: fill-box;
}

#ou {
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes four-anim {
  50% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
}

@keyframes four-anim {
  50% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
}
.no-data-btn:hover {
  background-color: blue !important;
  color: #fff !important;
}


.video-call-sec{
    margin-top: 0em;
    margin-bottom: 0em;
    overflow-x: hidden;
    /* height: calc(100vh - 48px); */
    height: 100vh;
}

.video-call-box{
 
    background-color: #ffffff;
    display: flex;
    width: 100%;
    /* align-items: center; */
}

.video-call-card-left{
    /* width: 70%; */
   /* padding-left: 4em;
   padding-right: 2em;
   padding-bottom: 4em; */
}

.video-call-card-right{
    /* width: 30%; */
    /* background-color: #EDF0F6; */
   /* padding-top:1em; */
}

.video-call-bg-img{
   
    display: block;
    position: relative;
    width: 100%;
    object-fit: contain;
  /* height: calc(100vh - 48px); */
  height: 100vh;
  }
  
  .video-call-card{
    position: relative;
        /* height: calc(100vh - 48px); */
        height: 100vh;
  }
  
  .video-call-card::before{
    background: linear-gradient(289.54deg, #000000 0.31%, rgba(0, 0, 0, 0) 19.22%);
    /* border-radius: 10px; */
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }
  
  .video-call-user-img-sec .video-call-user-img {
    width: 150px;
    height: 200px;
    position: absolute;
    right: 50px;
    top:50px;
    bottom: 5%;
    display: block;
    object-fit: cover;
    border-radius: 10px;
    border:4px solid var(--secondary-color);
    z-index: 2;
  }
  
  .video-call-action-btn-sec {
    position: absolute;
    bottom: 50px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 2;
  }
  
  .video-call-action-btn li {
    background-color: #eff1f2;
    height: 42px;
    width: 42px;
    border-radius: 100%;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border: 0 !important;
    margin-top: 0;
    margin-right: 2em;
    box-shadow: 0 5px 4px 0 rgb(0 0 0 / 26%);
  }
  
  .video-call-action-btn li:last-child {
    margin-right: 0;
  }
  
  .action-icon-img{
    width: 2em;
    height: 2em;
    object-fit: contain;
  }
  
  .video-call-action-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .video-call-action-btn li.call-animation {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    background-color: #ff4e2b;
    border-radius: 50%;
  }
  
  
  .back-btn-sec{
    position: absolute;
    left: 50px;
    top:50px;
    z-index: 2;
  }
  
  .back-btn-sec .back-btn-img{
    width: 0.8em;
  }
  .back-btn-sec a{
    background-color: #eff1f2;
    height: 42px;
    width: 42px;
    border-radius: 100%;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border: 0 !important;
    margin-top: 0;
    margin-right: 2em;
    box-shadow: 0 5px 4px 0 rgb(0 0 0 / 26%);
  }
  
  .video-call-send-tip-sec{
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    z-index: 2;
}

.video-call-send-tip-icon{
    max-width: 9em;
}

.video-call-send-tip-sec .send-tip-btn {
    border: 1px solid var(--secondary-color)!important;
    padding: 5px 15px!important;
    font-size: 1.2em;
    letter-spacing: 0.8px;
    color:var(--secondary-color);
    font-weight: 500;
    background: transparent!important;
    border-radius: 25px;
    transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
    display: inline-block;
}

.video-call-send-tip-sec .send-tip-btn:hover,
.video-call-send-tip-sec .send-tip-btn:focus,
.video-call-send-tip-sec .send-tip-btn:active{
    background: transparent!important;
    color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color)!important;
}


.video-call-user-info{
  display: flex;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 0em 1em 1em;
}

.video-call-user-info h4{
  display: flex;
  align-items: center;
  grid-gap:0.2em;
  gap:0.2em;
}

.user-follow-count{
  width:3em;
}

.video-user-follow-count{
  display: flex;
  align-items: center;
  grid-gap:0.5em;
  gap:0.5em;
}

.video-user-follow-count span{
  font-size: 1.4em;
  font-weight: 500;
}

.video-call-chat-header-sec{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.video-call-user-img{
  width: 4em;
  height: 4em;
  object-fit:cover ;
  border-radius: 50%;
}

.video-call-chat-box{
  background-color: #EDF0F6;
  /* border-radius: 10px; */
  padding: 1em;
  /* margin: 1em 0; */
}

.video-call-chat-header h3{
  font-size: 1.5em;
  color: var(--quaternary-color);
  font-weight: 600;
  margin-bottom: 0;
}

.video-call-chat-header{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1em;
  padding-bottom: 1em;
}

.video-call-date{
  font-size: 1em;
  color: var(--quaternary-color);
  font-weight: 500;
  text-align: center;
}

.video-call-chat-right .video-call-chat-bg{
  background: #F2F0E4;
  box-shadow: 0px 0.772771px 1.54554px rgba(0, 0, 0, 0.25);
  display: inline-block;
  padding:8px 12px;
  border-radius: 25px;
}

.video-call-chat-right .video-call-chat-bg p{
  margin-bottom: 0;
  font-size: 1.3em;
  font-weight: 500;
  color: var(--teritary-color);
}

.video-call-chat-right{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  margin: 0.5em 0;
  grid-gap:0.8em;
  gap:0.8em;
}

.video-call-chat-left .video-call-chat-bg{
  background: var(--primary-color);
  box-shadow: 0px 0.772771px 1.54554px rgba(0, 0, 0, 0.25);
  display: inline-block;
  padding:8px 12px;
  border-radius: 25px;
}

.video-call-chat-left .video-call-chat-bg p{
  margin-bottom: 0;
  font-size: 1.3em;
  font-weight: 500;
  color:var(--secondary-color);
}

.video-call-chat-left{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 0.5em 0;
  grid-gap:0.8em;
  gap:0.8em;
}

.video-call-chat-footer{
  padding: 1em 1em; 
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

/* .btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
} */

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.video-call-chat-footer .upload-btn-wrapper .icon-btn{
  border: 0!important;
  background: transparent!important;
  padding: 0px 0!important;
}

.video-call-chat-footer .upload-btn-wrapper .icon-btn:hover,
.video-call-chat-footer .upload-btn-wrapper .icon-btn:focus,
.video-call-chat-footer .upload-btn-wrapper .icon-btn:active{
  border: 0!important;
  background: transparent!important;
}

.video-call-add-message-sec {
  background: var(--secondary-color);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 10px 10px;
  position: fixed;
  bottom: 15px;
  right: 15px;
 width: 23%;
}
.video-call-chat-room{
  overflow-x: hidden;
}

/* .audio-call-sec .audio-call-card .video-call-add-message-sec{

} */

.video-call-add-message-sec .input-group-prepend .input-group-text{
  background-color: transparent!important;
  border: 0!important;
  padding-left: 0!important;
  padding-bottom: 0;
  padding-top: 0;
}

.video-call-add-message-sec .input-group-append .input-group-text{
  background-color: transparent!important;
  border: 0!important;
  padding-right: 0!important;
  padding-bottom: 0;
  padding-top: 0;
}

.video-call-add-message-sec .attach-icon{
  width: 2em;
}

.video-call-add-message-sec .smiley-icon{
  width: 2em;
}

.video-call-add-message-sec .form-control{
  background: transparent!important;
  border: 0!important;
}

.video-call-add-message-sec .form-control:focus{
  background: transparent!important;
  border: 0!important;
}

.video-call-add-message-sec .form-control::-webkit-input-placeholder{
  font-weight: 400;
  font-size: 0.9em;
}

.video-call-add-message-sec .form-control::placeholder{
  font-weight: 400;
  font-size: 0.9em;
}

.video-call-add-message-sec .input-group-append .icon-btn{
  border: 0!important;
  background: transparent!important;
  padding: 0px 0!important;
}

.video-call-add-message-sec .input-group-append .icon-btn:hover,
  .video-call-add-message-sec .input-group-append .icon-btn:focus,
  .video-call-add-message-sec .input-group-append .icon-btn:active{
  border: 0!important;
  background: transparent!important;
  padding: 0px 0!important;
}

/*Audio Call CSS*/

.audio-call-sec{
  margin-top: 0em;
  margin-bottom: 0em;
  overflow-x: hidden;
  /* height: 94vh; */
  overflow: hidden;
  /* height: calc(100vh - 48px); */
  height: 100vh;
}

.audio-call-box{
  background-color: #ffffff;
  display: flex;
  width: 100%;
}

.audio-call-bg{
  /* height: 94vh; */
  display: block;
  position: relative;
  width: 100%;
  object-fit: cover;
  /* border-radius: 8px; */
  /* background: radial-gradient(50% 50% at 50% 50%, #505662 0%, #333C4D 100%); */
  background: linear-gradient(180deg, #FFBA3B, #000000 100%);
      /* height: calc(100vh - 48px); */
      height: 100vh;
  
}

.audio-call-bg-img{
  height: 83vh;
  display: block;
  position: relative;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.audio-call-card-center{
  width: 100%;
}

.audio-call-card{
  position: relative;
  /* margin-top: 3em; */
}

/* .audio-call-card::before{
  background: linear-gradient(289.54deg, #000000 0.31%, rgba(0, 0, 0, 0) 19.22%);
  border-radius: 10px;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
} */

.audio-call-action-btn-sec {
  position: absolute;
  bottom: 50px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 2;
}

.audio-call-action-btn li {
  background-color: #eff1f2;
  height: 42px;
  width: 42px;
  border-radius: 100%;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border: 0 !important;
  margin-top: 0;
  margin-right: 2em;
  box-shadow: 0 5px 4px 0 rgb(0 0 0 / 26%);
}

.audio-call-action-btn li:last-child {
  margin-right: 0;
}

.audio-call-action-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.audio-call-action-btn li.call-animation {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
          transform: translate3d(0, 0, 0) scale(1);
  background-color: #ff4e2b;
  border-radius: 50%;
}


.back-btn-sec{
  position: absolute;
  left: 50px;
  top:50px;
  z-index: 2;
}

.back-btn-sec .back-btn-img{
  width: 0.8em;
}
.back-btn-sec a{
  background-color: #eff1f2;
  height: 42px;
  width: 42px;
  border-radius: 100%;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border: 0 !important;
  margin-top: 0;
  margin-right: 2em;
  box-shadow: 0 5px 4px 0 rgb(0 0 0 / 26%);
}

.audio-call-send-tip-sec{
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  z-index: 2;
}

.audio-call-send-tip-icon{
  max-width: 9em;
}

.audio-call-send-tip-sec .send-tip-btn {
  border: 1px solid var(--secondary-color)!important;
  padding: 5px 15px!important;
  font-size: 1.2em;
  letter-spacing: 0.8px;
  color:var(--secondary-color);
  font-weight: 500;
  background: transparent!important;
  border-radius: 25px;
  transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
  display: inline-block;
}

.audio-call-send-tip-sec .send-tip-btn:hover,
.audio-call-send-tip-sec .send-tip-btn:focus,
.audio-call-send-tip-sec .send-tip-btn:active{
  background: transparent!important;
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color)!important;
}

.audio-call-connected-box{
  position: absolute;
  top: 200px;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  place-items: center;
  z-index: 2;
}

.audio-call-connected-center-sec .connected-status p{
  font-size: 1.5em;
  color: var(--secondary-color);
  margin-bottom: 0;
}

.audio-call-user-img{
  height: 10em;
  object-fit: cover;
}

.audio-call-connected-left-sec{
  text-align: center;
}

.audio-call-connected-right-sec{
  text-align: center;
}

.audio-call-connected-center-sec{
  text-align: center;
}

.audio-call-connected-left-sec h4{
  font-size: 1.5em;
  color: var(--secondary-color);
  font-weight: 600;
  letter-spacing: 0.8px;
  margin-top:1em;
}

.audio-call-connected-right-sec h4{
  font-size: 1.5em;
  color: var(--secondary-color);
  font-weight: 600;
  letter-spacing: 0.8px;
  margin-top:1em;
}

.audio-call-connected-icon-sec{
  padding:2em 0;
}

.audio-call-amount-spend-details h4 {
    font-size: 1.4em;
    font-weight: 500;
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin-bottom: 0;
    color: var(--secondary-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
}

.audio-call-amount-spend-details h4 span {
    margin-left: 0.2em;
}

/* Audio Call Chat CSS*/

.audio-call-chat-sec{
  margin-top: 48px;
  margin-bottom: 4em;
  overflow-x: hidden;
}

.audio-call-chat-box{
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  width: 100%;
}

.audio-call-chat-left-sec{
  width: 70%;
 padding-left: 4em;
 padding-right: 2em;
}

.audio-call-chat-right-sec{
  width: 30%;
 padding-top:2em;
 padding-right: 2em;
}

.audio-call-chat-user-cover-img-sec .audio-call-chat-user-cover-img-sec{
  width: 100%;
  height: 15em;
  object-fit: cover;
  border-radius: 10px;
}

.audio-call-chat-user-card{
  background-color: #EDF0F6;
  padding: 2em;
  border-radius: 10px;
}

.audio-call-chat-user-img-sec{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4em;
  margin-left: 0;
  margin-bottom: 1em;
}

.audio-call-chat-user-img{
  width: 8em;
  height: 8em;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #fff;
  padding: 2px;
  object-fit: cover;
}

.audio-call-chat-user-card h4 {
  font-size: 1.4em;
  font-weight: 500;
  display: flex;
  align-items: center;
  grid-gap: 0.5em;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin-bottom: 0;
  color: var(--teritary-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
}

.audio-call-chat-user-card h4 span {
  margin-left: 0.2em;
}

.view-details-btn-sec{
  margin-top: 0.5em;
}

.view-details-btn-sec .view-details-btn{
  color: var(--secondary-color);
  border: 0;
  font-size: 1.5em;
  padding: 10px 20px;
  font-weight: 500;
  display: inline-block;
  border-radius: 5px;
  background-color: var(--primary-color)!important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.view-details-btn-sec .view-details-btn:hover,
.view-details-btn-sec .view-details-btn:focus,
.view-details-btn-sec .view-details-btn:active{
  background-color: var(--primary-color)!important;
  color: var(--secondary-color)!important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.audio-total-count-info-box {
  grid-template-columns: repeat(3,1fr);
  display: grid;
  place-items: center;
  text-align: center;
  width: 100%;
}

.audio-total-count-card {
  position: relative;
}

.audio-total-count-card:before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid rgba(0,0,0,.1);
  top: 0;
  right: -35px;
}

.audio-total-count-card:last-child::before{
  display: none;
}

.audio-total-count-card h5 {
  font-size: 1.3em;
  font-weight: 700;
}

.audio-total-count-card p {
  color: #9896a1;
  margin-bottom: 0;
  font-size: 1.1em;
  font-weight: 500;
}

.audio-call-chat-user-item{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
  text-align: center;
  margin-bottom: 1em;
}

.video-call-mobile-sec{
  display: none;
  align-items: center;
  grid-gap:0.5em;
  gap:0.5em;
  justify-content: flex-end;
  margin-top: 2em;
  margin-right: 2em;
}

.video-call-chat-room .no-data-found-sec .no-data-found-img-sec img {
    max-width: 20em;
}

.video-call-chat-box ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.video-call-chat-box ::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.video-call-chat-box ::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

.live-streaming-user-add-comments-sec .input-group-append .input-group-text{
  padding-right: 0;
}

.live-streaming-user-add-comments-sec .input-group-append .icon-btn{
  padding-right: 0;
}

.agora_video_player{
  object-fit: contain!important;
}

.new-audio-mute-option-icon{
  width: 1.5em;
}


.meeting-room-header-sec{
    background: #FFFFFF;
    border-width: 1.5px 0px 1.5px 1.5px;
    border-style: solid;
    border-color: rgba(217, 217, 217, 0.29);
}

.meeting-room-sec{
    background-color: #EAE9E9;
    min-height: 100vh;
    font-family: 'Inter', sans-serif!important;
}

.meeting-room-logo-sec{
    border-right: 2px solid rgba(234, 233, 233, 0.58);
    display: flex;
    align-items: center;
    justify-content: center;
}

.meeting-room-header-sec{
    display: grid;
    grid-template-columns: 150px auto;
}

.meeting-details-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
    grid-gap: 3em;
    gap: 3em;
}

.meeting-user-details{
    border-radius: 100px;
    background-color: #F6F6F6;
    padding: 1em;
    display: flex;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    min-width: 335px;
}

.meeting-info-item h4{
    color: #0095FF;
    font-size: 1.5em;
    font-weight: 600;
}

.meeting-info-item p{
    font-size: 1.1em;
    color: #ACACAC;
    margin-bottom: 0.5em;
    max-width: 600px;
}

.meeting-user-img{
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    width: 4em;
    height: 4em;
    object-fit: cover;
}

.meeting-user-info h4{
    color: #25293B;
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 0;
}

.meeting-user-info p{
    font-size: 1.2em;
    color: #ACACAC;
    margin-bottom: 0;
}

.meeting-room-body-sec{
    /* background-color: #ffffff; */
    display: flex;
    width: 100%;
}

.meeting-room-body-left-sec {
    width: 75%;
    padding: 2em;
}

.meeting-room-body-right-sec {
    width: 25%;
    background-color: #f9fafc;
    height: auto;
}

.meeting-room-admin-img{
    width: 100%;
    border-radius: 15px;
    height: 40em;
    object-fit: cover;
}

.meeting-room-admin-card{
    position: relative;
}

.meeting-admin-timer{
    position: absolute;
    top: 15px;
    left: 15px;
    background: rgba(15, 14, 14, 0.3);
    -webkit-backdrop-filter: blur(7.5px);
            backdrop-filter: blur(7.5px);
    border-radius: 76px;
    padding: 1em 1.5em;
    color:#fff;
    display: flex;
    align-items: center;
    grid-gap: 0.8em;
    gap: 0.8em;
}

.meeting-admin-timer span{
    color:#fff;
    font-weight: 500;
    font-size: 1.2em;
}

.meeting-admin-name{
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(4.08333px);
            backdrop-filter: blur(4.08333px);
    border-radius: 89.8333px;
    padding: 1em 1.5em;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.meeting-admin-name span{
    color:#fff;
    font-weight: 500;
    font-size: 1.2em;
}

.meeting-admin-full-screen{
    background: rgba(15, 14, 14, 0.3);
    -webkit-backdrop-filter: blur(7.5px);
            backdrop-filter: blur(7.5px);
    border-radius: 76px;
    width: 4em;
    height: 4em;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
}

.meeting-admin-audio-speech{
    background: rgba(15, 14, 14, 0.3);
    -webkit-backdrop-filter: blur(7.5px);
            backdrop-filter: blur(7.5px);
    border-radius: 76px;
    width: 4em;
    height: 4em;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.meeting-admin-audio-img{
    width: 25px;
}

.meeting-user-name{
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(4.08333px);
            backdrop-filter: blur(4.08333px);
    border-radius: 89.8333px;
    padding: 1em 1.5em;
    position: absolute;
    bottom: 10px;
    left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: horizontal;
}

.meeting-user-name span{
    color:#fff;
    font-weight: 500;
    font-size: 1.1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}


.meeting-room-user-card{
    position: relative;
}

.meeting-room-user-img{
    border-radius: 15px;
    width: 25.2em;
    height: 15.35em;
    object-fit: cover;
    object-position: center;
}

.meeting-room-user-box{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-top: 1.5rem;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: scroll auto;
    width: 100%;
}

.meeting-room-user-card{
    /* flex: 1 1 0%; */
    min-width: auto;
    flex: 0 0 auto;
    /* width: 25%; */
}


.meeting-user-unmute-bg{
    background: #EB5757;
    -webkit-backdrop-filter: blur(3.87633px);
            backdrop-filter: blur(3.87633px);
    border-radius: 80.8492px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meeting-user-mute-bg{
    background: #9BC53E;
    -webkit-backdrop-filter: blur(3.87633px);
            backdrop-filter: blur(3.87633px);
    border-radius: 80.8492px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.particpate-mute-audio-icon{
    width: 2.5em;
}

.meeting-user-unmute-icon{
    width: 2.5em;
}

.meeting-user-mute-icon{
    width: 2.5em;
}

.meeting-room-admin-action-btn-sec{
    background: #FFFFFF;
    border: 1.5px solid #EDF0F6;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    padding: 1.5em;
    margin-top: 1.5rem;
    border-radius: 15px;
    margin-bottom: 0;
}

.meeting-room-admin-action-btn li{
    -webkit-backdrop-filter: blur(3.87633px);
            backdrop-filter: blur(3.87633px);
    border-radius: 80.8492px;
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-mute{
    background: linear-gradient(140.6deg, #D0D0EA 3.22%, rgba(188, 187, 228, 0.53) 95.17%);;
}

.bg-action{
    background-color:#FE4307;
}

.meeting-room-admin-action-btn{
    display: flex;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    justify-content: center;
    margin-bottom: 0;
    margin: auto;
    width: 100%;
}

iframe{
    display: none;
}

.end-call-btn{
    background-color:  #FE4307!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color:  #FE4307!important;
    padding: 0.6em 2em!important;
    font-size: 1.4em!important;
    font-weight: 500!important;
    color: #fff!important;
    margin-left: auto;
    display: flex!important;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
}

.end-call-btn:hover,
.end-call-btn:focus,
.end-call-btn:active{
    background-color:  #FE4307!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color:  #FE4307!important;
    color: #fff!important;
}

.meeting-room-admin-action-btn-sec{
    display: flex;
    align-items: center;
    justify-content: center;
}

.meeting-room-participate-header-sec{
    background: #FFFFFF;
    border-bottom: 1px solid #FDFBFB;
    padding: 2em;
}

.meeting-room-participate-header-sec h3{
    margin-bottom: 0;
    color: #292D32;
    font-size: 1.6em;
    font-weight: 600;
}

.meeting-room-participate-header-sec h3 span{
    color: var(--primary-color);
}

.meeting-room-participate-list-sec{
    padding: 1.5em;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.meeting-room-participate-list{
    margin-bottom: 0;
}

.meeting-room-participate-card{
    border-radius: 100px;
    /* background-color: #F6F6F6; */
    padding: 1.5em;
    display: flex;
    align-items: center;
    grid-gap: 1em;
    grid-gap: 1em;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.8);
}

.meeting-room-particpate-left-sec{
    display: flex;
    align-items: center;
    grid-gap: 1em;
    grid-gap: 1em;
    grid-gap: 1em;
    gap: 1em;
}

.meeting-room-participate-img{
    border-radius: 50%;
    width: 4.5em;
    height: 4.5em;
    object-fit: cover;
}

.meeting-room-participate-info h4 {
    color: #25293B;
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width: 150px; */
}

.meeting-room-particpate-right-sec .particpate-mute-audio-btn{
    background-color: transparent!important;
    border: 0!important;
    cursor: unset!important;
}

.meeting-room-particpate-right-sec .participate-hand-raise-btn{
    background-color: transparent!important;
    border: 0!important;
    cursor: unset!important;
}

.more-action-btn{
    background-color: #dfebff!important;
    border: 0!important;
    -webkit-backdrop-filter: blur(3.87633px)!important;
    backdrop-filter: blur(3.87633px)!important;
    border-radius: 80.8492px!important;
    width: 3.5em!important;
    height: 3.5em!important;
    padding: 0!important;
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.more-action-btn:hover,
.more-action-btn:focus,
.more-action-btn:active{
    background-color: #dfebff!important;
    border-color: #dfebff!important;
    border: 0!important;
    -webkit-backdrop-filter: blur(3.87633px)!important;
    backdrop-filter: blur(3.87633px)!important;
    border-radius: 80.8492px!important;
    outline: none!important;
    box-shadow: none!important;
}

.meeting-room-particpate-right-sec .particpate-mute-video-btn {
    background-color: transparent!important;
    border: 0!important;
    cursor: unset!important;
}

.meeting-room-participate-list li{
    padding-bottom: 1em;
}

.meeting-room-body-right-sec .close-mobile{
    display: none;
}

.mobile-menu-sec{
    display: none;
  }

  .user-audio-btn{
    background-color: transparent!important;
    border: 0!important;
    padding: 0!important;
  }


  .meeting-room-sec ::-webkit-scrollbar {
    background-color: #EAE9E9;
    width: 16px
}

/* background of the scrollbar except button or resizer */
.meeting-room-sec ::-webkit-scrollbar-track {
    background-color: #EAE9E9
}

.meeting-room-sec ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4
}

/* scrollbar itself */
.meeting-room-sec ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #EAE9E9
}

.meeting-room-sec ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.meeting-room-sec ::-webkit-scrollbar-button {
    display: none
}

.meeting-room-particpate-right-sec{
    display: flex;
    align-items: center;
}


.meeting-info-item{
    display: flex;
    grid-gap: 5em;
    gap: 5em;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.view-more-btn{
    background-color: transparent!important;
    color: var(--primary-color)!important;
    border: 0!important;
    padding: 0.5em 0!important;
    font-size: 1em;
    margin-right: 0.5em;
    padding-bottom: 0!important;
}

.agenda-btn{
    background-color: var(--primary-color)!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color: var(--primary-color)!important;
    padding: 0.6em 2em!important;
    font-size: 1.2em!important;
    font-weight: 500!important;
    color: var(--secondary-color)!important;
}

.agenda-btn:hover,
.agenda-btn:focus,
.agenda-btn:active{
    background-color: var(--primary-color)!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color: var(--primary-color)!important;
    color: var(--secondary-color)!important;
}

.agenda-modal .modal-header .close{
    margin: 0!important;
}

.agenda-modal .modal-header{
    background-image: unset!important;
}

.agenda-sec p{
    font-size: 1.2em;
    color: #ACACAC;
    margin-bottom: 0.5em;
}

.no-particpate-img-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 82vh;
}

.no-particpate-img{
    max-width: 20em;
}

.meeting-room-content-sec{
    background: #FFFFFF;
    border-bottom: 1px solid #FDFBFB;
    padding: 2em;
    border-radius: 15px;
    margin-bottom: 2em;
}

.desc-meeting-info{
    font-size: 1.1em;
    color: #ACACAC;
    margin-bottom: 0em;
}

.meeting-user-unmute-all-icon{
    width: 2em;
}

.handraise-hide{
    display:none;
}

.handraise-show{
    display:block;
}

.meeting-room-admin-action-icon{
    width: 2em;
}
.agent-meeting-room-sec{
    display: flex;
    min-height: 100vh;
    font-family: 'Inter', sans-serif!important;
}

.agent-meeting-room-left-sec{
    background-color: #E6E8ED;
    padding: 2em;
    width: 75%;
}

.agent-meeting-room-right-sec{
    width: 25%;
    background-color: #FAFAFB;
    height: auto;
}

.agent-meeting-info-item{
    display: flex;
    grid-gap: 2em;
    gap: 2em;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.agent-meeting-info-item h4 {
    color: #343434;
    font-size: 2em;
    font-weight: 600;
}

.agent-meeting-info-item p {
    font-size: 1.2em;
    color: #55495F;
    margin-bottom: 0.5em;
    max-width: 600px;
}

.agent-agenda-btn{
    background-color: #1F2730!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color: #1F2730!important;
    padding: 0.8em 2em!important;
    font-size: 1.4em!important;
    font-weight: 500!important;
    color: var(--secondary-color)!important;
}

.agent-agenda-btn:hover,
.agent-agenda-btn:focus,
.agent-agenda-btn:active{
    background-color: #1F2730!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color: #1F2730!important;
    color: var(--secondary-color)!important;
}
.landing-page-sec{
    /* background: linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84));
    background: -webkit-linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84)); */
    /* background-color: rgb(7 16 45); */
    min-height: 100vh;
    position: relative;
}

.landing-page-sec::before{
    position: absolute;
    content: "";
    background: url(https://cityplots.in/assets/images/about/hero-1.png);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-transform: scaley(-1);
            transform: scaley(-1);
}
.landing-page-sec .card {
    /* max-width: 350px; */
    /* background-color: rgb(7 16 45); */
    /* background: linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84));
    background: -webkit-linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84)); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    border-radius: 20px;
    border: 0;
    background-color: transparent!important;
  }
  
  .landing-page-sec .small-text, .title,.desc {
    font-weight: 600;
  }
  
  .landing-page-sec .title,.desc {
    margin: 8px 0;
  }
  
  .landing-page-sec .desc {
    color: #fff;
    font-size: 1.6em;
    padding: 1em 0;
  }
  
  .landing-page-sec .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 2em;
    gap: 2em;
    margin-top: 10px;
  }
  
  .landing-page-sec .custom-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px 30px;
    text-decoration: none;
  }
  
  .landing-page-sec .custom-links:first-child {
    box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
    background-color: #488aec;
  }
  
  .landing-page-sec .custom-links:last-child {
    box-shadow: 0 4px 6px -1px #0b162531, 0 2px 4px -1px #0e192b17;
    background-color: #fff;
  }
  
  .landing-page-sec .app-icons {
    height: 25px;
    width: 25px;
  }
  
  .landing-page-sec .app-icons svg {
    width: 100%;
    height: 100%;
  }
  
  .landing-page-sec .app-icons svg:last-child {
    fill: #000
  }
  
  .landing-page-sec .button-text {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
  
  .landing-page-sec .button-text span:first-child {
    font-size: 12px;
    font-weight: 600;
  }
  
  .landing-page-sec .google span:first-child {
    color: rgb(219 206 253);
  }
  
  .landing-page-sec .apple span:first-child {
    color: rgb(81 87 108);
  }
  
  .landing-page-sec .google span:last-child {
    color: #fff;
    font-weight: 800;
  }
  
  .landing-page-sec .apple span:last-child {
    color: #000;
    font-weight: 800;
  }

  .landing-page-sec .landing-logo{
    max-width: 20em;
  }
