
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


.meeting-room-header-sec{
    background: #FFFFFF;
    border-width: 1.5px 0px 1.5px 1.5px;
    border-style: solid;
    border-color: rgba(217, 217, 217, 0.29);
}

.meeting-room-sec{
    background-color: #EAE9E9;
    min-height: 100vh;
    font-family: 'Inter', sans-serif!important;
}

.meeting-room-logo-sec{
    border-right: 2px solid rgba(234, 233, 233, 0.58);
    display: flex;
    align-items: center;
    justify-content: center;
}

.meeting-room-header-sec{
    display: grid;
    grid-template-columns: 150px auto;
}

.meeting-details-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
    gap: 3em;
}

.meeting-user-details{
    border-radius: 100px;
    background-color: #F6F6F6;
    padding: 1em;
    display: flex;
    align-items: center;
    gap: 1em;
    min-width: 335px;
}

.meeting-info-item h4{
    color: #0095FF;
    font-size: 1.5em;
    font-weight: 600;
}

.meeting-info-item p{
    font-size: 1.1em;
    color: #ACACAC;
    margin-bottom: 0.5em;
    max-width: 600px;
}

.meeting-user-img{
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    width: 4em;
    height: 4em;
    object-fit: cover;
}

.meeting-user-info h4{
    color: #25293B;
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 0;
}

.meeting-user-info p{
    font-size: 1.2em;
    color: #ACACAC;
    margin-bottom: 0;
}

.meeting-room-body-sec{
    /* background-color: #ffffff; */
    display: flex;
    width: 100%;
}

.meeting-room-body-left-sec {
    width: 75%;
    padding: 2em;
}

.meeting-room-body-right-sec {
    width: 25%;
    background-color: #f9fafc;
    height: auto;
}

.meeting-room-admin-img{
    width: 100%;
    border-radius: 15px;
    height: 40em;
    object-fit: cover;
}

.meeting-room-admin-card{
    position: relative;
}

.meeting-admin-timer{
    position: absolute;
    top: 15px;
    left: 15px;
    background: rgba(15, 14, 14, 0.3);
    backdrop-filter: blur(7.5px);
    border-radius: 76px;
    padding: 1em 1.5em;
    color:#fff;
    display: flex;
    align-items: center;
    gap: 0.8em;
}

.meeting-admin-timer span{
    color:#fff;
    font-weight: 500;
    font-size: 1.2em;
}

.meeting-admin-name{
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4.08333px);
    border-radius: 89.8333px;
    padding: 1em 1.5em;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.meeting-admin-name span{
    color:#fff;
    font-weight: 500;
    font-size: 1.2em;
}

.meeting-admin-full-screen{
    background: rgba(15, 14, 14, 0.3);
    backdrop-filter: blur(7.5px);
    border-radius: 76px;
    width: 4em;
    height: 4em;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
}

.meeting-admin-audio-speech{
    background: rgba(15, 14, 14, 0.3);
    backdrop-filter: blur(7.5px);
    border-radius: 76px;
    width: 4em;
    height: 4em;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.meeting-admin-audio-img{
    width: 25px;
}

.meeting-user-name{
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4.08333px);
    border-radius: 89.8333px;
    padding: 1em 1.5em;
    position: absolute;
    bottom: 10px;
    left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: horizontal;
}

.meeting-user-name span{
    color:#fff;
    font-weight: 500;
    font-size: 1.1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}


.meeting-room-user-card{
    position: relative;
}

.meeting-room-user-img{
    border-radius: 15px;
    width: 25.2em;
    height: 15.35em;
    object-fit: cover;
    object-position: center;
}

.meeting-room-user-box{
    display: flex;
    flex-direction: row;
    -webkit-box-align: stretch;
    align-items: stretch;
    margin-top: 1.5rem;
    gap: 1rem;
    overflow: scroll auto;
    width: 100%;
}

.meeting-room-user-card{
    /* flex: 1 1 0%; */
    min-width: auto;
    flex: 0 0 auto;
    /* width: 25%; */
}


.meeting-user-unmute-bg{
    background: #EB5757;
    backdrop-filter: blur(3.87633px);
    border-radius: 80.8492px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meeting-user-mute-bg{
    background: #9BC53E;
    backdrop-filter: blur(3.87633px);
    border-radius: 80.8492px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.particpate-mute-audio-icon{
    width: 2.5em;
}

.meeting-user-unmute-icon{
    width: 2.5em;
}

.meeting-user-mute-icon{
    width: 2.5em;
}

.meeting-room-admin-action-btn-sec{
    background: #FFFFFF;
    border: 1.5px solid #EDF0F6;
    backdrop-filter: blur(4px);
    padding: 1.5em;
    margin-top: 1.5rem;
    border-radius: 15px;
    margin-bottom: 0;
}

.meeting-room-admin-action-btn li{
    backdrop-filter: blur(3.87633px);
    border-radius: 80.8492px;
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-mute{
    background: linear-gradient(140.6deg, #D0D0EA 3.22%, rgba(188, 187, 228, 0.53) 95.17%);;
}

.bg-action{
    background-color:#FE4307;
}

.meeting-room-admin-action-btn{
    display: flex;
    align-items: center;
    gap: 2em;
    justify-content: center;
    margin-bottom: 0;
    margin: auto;
    width: 100%;
}

iframe{
    display: none;
}

.end-call-btn{
    background-color:  #FE4307!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color:  #FE4307!important;
    padding: 0.6em 2em!important;
    font-size: 1.4em!important;
    font-weight: 500!important;
    color: #fff!important;
    margin-left: auto;
    display: flex!important;
    align-items: center;
    gap: 1em;
}

.end-call-btn:hover,
.end-call-btn:focus,
.end-call-btn:active{
    background-color:  #FE4307!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color:  #FE4307!important;
    color: #fff!important;
}

.meeting-room-admin-action-btn-sec{
    display: flex;
    align-items: center;
    justify-content: center;
}

.meeting-room-participate-header-sec{
    background: #FFFFFF;
    border-bottom: 1px solid #FDFBFB;
    padding: 2em;
}

.meeting-room-participate-header-sec h3{
    margin-bottom: 0;
    color: #292D32;
    font-size: 1.6em;
    font-weight: 600;
}

.meeting-room-participate-header-sec h3 span{
    color: var(--primary-color);
}

.meeting-room-participate-list-sec{
    padding: 1.5em;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.meeting-room-participate-list{
    margin-bottom: 0;
}

.meeting-room-participate-card{
    border-radius: 100px;
    /* background-color: #F6F6F6; */
    padding: 1.5em;
    display: flex;
    align-items: center;
    grid-gap: 1em;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.8);
}

.meeting-room-particpate-left-sec{
    display: flex;
    align-items: center;
    grid-gap: 1em;
    grid-gap: 1em;
    gap: 1em;
}

.meeting-room-participate-img{
    border-radius: 50%;
    width: 4.5em;
    height: 4.5em;
    object-fit: cover;
}

.meeting-room-participate-info h4 {
    color: #25293B;
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width: 150px; */
}

.meeting-room-particpate-right-sec .particpate-mute-audio-btn{
    background-color: transparent!important;
    border: 0!important;
    cursor: unset!important;
}

.meeting-room-particpate-right-sec .participate-hand-raise-btn{
    background-color: transparent!important;
    border: 0!important;
    cursor: unset!important;
}

.more-action-btn{
    background-color: #dfebff!important;
    border: 0!important;
    -webkit-backdrop-filter: blur(3.87633px)!important;
    backdrop-filter: blur(3.87633px)!important;
    border-radius: 80.8492px!important;
    width: 3.5em!important;
    height: 3.5em!important;
    padding: 0!important;
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.more-action-btn:hover,
.more-action-btn:focus,
.more-action-btn:active{
    background-color: #dfebff!important;
    border-color: #dfebff!important;
    border: 0!important;
    -webkit-backdrop-filter: blur(3.87633px)!important;
    backdrop-filter: blur(3.87633px)!important;
    border-radius: 80.8492px!important;
    outline: none!important;
    box-shadow: none!important;
}

.meeting-room-particpate-right-sec .particpate-mute-video-btn {
    background-color: transparent!important;
    border: 0!important;
    cursor: unset!important;
}

.meeting-room-participate-list li{
    padding-bottom: 1em;
}

.meeting-room-body-right-sec .close-mobile{
    display: none;
}

.mobile-menu-sec{
    display: none;
  }

  .user-audio-btn{
    background-color: transparent!important;
    border: 0!important;
    padding: 0!important;
  }


  .meeting-room-sec ::-webkit-scrollbar {
    background-color: #EAE9E9;
    width: 16px
}

/* background of the scrollbar except button or resizer */
.meeting-room-sec ::-webkit-scrollbar-track {
    background-color: #EAE9E9
}

.meeting-room-sec ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4
}

/* scrollbar itself */
.meeting-room-sec ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #EAE9E9
}

.meeting-room-sec ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.meeting-room-sec ::-webkit-scrollbar-button {
    display: none
}

.meeting-room-particpate-right-sec{
    display: flex;
    align-items: center;
}


.meeting-info-item{
    display: flex;
    gap: 5em;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.view-more-btn{
    background-color: transparent!important;
    color: var(--primary-color)!important;
    border: 0!important;
    padding: 0.5em 0!important;
    font-size: 1em;
    margin-right: 0.5em;
    padding-bottom: 0!important;
}

.agenda-btn{
    background-color: var(--primary-color)!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color: var(--primary-color)!important;
    padding: 0.6em 2em!important;
    font-size: 1.2em!important;
    font-weight: 500!important;
    color: var(--secondary-color)!important;
}

.agenda-btn:hover,
.agenda-btn:focus,
.agenda-btn:active{
    background-color: var(--primary-color)!important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 50px!important;
    border-color: var(--primary-color)!important;
    color: var(--secondary-color)!important;
}

.agenda-modal .modal-header .close{
    margin: 0!important;
}

.agenda-modal .modal-header{
    background-image: unset!important;
}

.agenda-sec p{
    font-size: 1.2em;
    color: #ACACAC;
    margin-bottom: 0.5em;
}

.no-particpate-img-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 82vh;
}

.no-particpate-img{
    max-width: 20em;
}

.meeting-room-content-sec{
    background: #FFFFFF;
    border-bottom: 1px solid #FDFBFB;
    padding: 2em;
    border-radius: 15px;
    margin-bottom: 2em;
}

.desc-meeting-info{
    font-size: 1.1em;
    color: #ACACAC;
    margin-bottom: 0em;
}

.meeting-user-unmute-all-icon{
    width: 2em;
}

.handraise-hide{
    display:none;
}

.handraise-show{
    display:block;
}

.meeting-room-admin-action-icon{
    width: 2em;
}